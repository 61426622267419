$animation-time: 0.3s;
$pulse-time: 1.2s;

.container {
  padding: 190px 240px 0px 297px;
  background: url('../../Assets//images/cloud_bg.png');
  height: 1076px;
  width: 100%;
  background-position: bottom;
  position: relative;
  z-index: 2;
}

.card {
  border-radius: 20px;
  background: #fdfdfd;
  box-shadow: 0px 0px 10px 0px rgba(0, 120, 187, 0.2);
  height: 410px;
}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 150px;
}

.circles {
  background-image: url('../../Assets/images/white_circles.png');
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 0;
  left: 45%;
}

.btn_pulse {
  display: flex;
  width: 150px;
  height: 150px;
  padding: 35px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background: var(--color-gray-7);
  box-shadow: 0px 0px 8px 1px rgba(166, 216, 227, 0.35);
  position: relative;
  transition: all $animation-time linear;
  z-index: 1000;
  &__bg {
    position: absolute;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    width: 150px;
    height: 150px;
    padding: 35px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    background: var(--color-gray-7);
    box-shadow: 0px 0px 8px 1px rgba(166, 216, 227, 0.35);
    position: relative;
    transition: all $animation-time linear;
    z-index: 1000;
  }
  &:before {
    content: '';
    position: absolute;
    width: 99%;
    height: 99%;
    border-radius: 50%;
    border: 1px solid var(--color-blue-11);
    display: block;
    transition: all $animation-time linear;
    pointer-events: none;
    z-index: 100;
  }
  &:after {
    content: '';
    position: absolute;
    width: 99%;
    height: 99%;
    border-radius: 50%;
    border: 1px solid var(--color-blue-11);
    display: block;
    transition: all $animation-time linear;
    pointer-events: none;
    z-index: 10;
  }
  &__active {
    .btn_pulse__bg {
      background-color: #2ac5e7;
      rect {
        fill: var(--color-white);
      }
      path {
        stroke: var(--color-white);
      }
      svg path:nth-of-type(5) {
        fill: var(--color-white);
      }
      svg path:nth-of-type(6) {
        fill: var(--color-white);
      }
      svg path:nth-of-type(4) {
        fill: var(--color-white);
      }
    }
    &:before {
      animation: small-pulse $pulse-time linear infinite;
      background-color: #87dff266;
    }
    &:after {
      animation: big-pulse $pulse-time linear infinite;
      background-color: #93e2f44d;
    }
  }
  &:hover {
    transform: scale(1.1);
    &:before {
      width: 150%;
      height: 150%;
    }
    &:after {
      width: 200%;
      height: 200%;
    }
  }
}

.relative_wrap {
  position: relative;
  width: 600px;
  height: 267px;
}

.descr_wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  opacity: 0;
  transition: all 0.7s ease-out;
  &__active {
    opacity: 1;
  }
  .descr_item {
    gap: 10px;
    align-items: center;
    width: 100%;
    display: grid;
    grid-template-columns: 10px auto;
    &__text {
      color: var(--color-gray-6);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .blue_circle {
      width: 10px;
      height: 10px;
      background-color: var(--color-blue-7);
      border-radius: 50%;
    }
  }
}

.big_title {
  margin-top: 173px;
  margin-left: 100px;
  position: relative;
  .icon_wrap {
    opacity: 0;
    position: absolute;
    transition: all 0.7s ease-out;
    &__active {
      opacity: 1;
    }
  }
}

@keyframes small-pulse {
  0% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  50% {
    width: 125%;
    height: 125%;
    opacity: 1;
  }
  100% {
    width: 150%;
    height: 150%;
    opacity: 0;
  }
}

@keyframes big-pulse {
  0% {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  50% {
    width: 150%;
    height: 150%;
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
