.popup_button {
    position: fixed;
    right: 90px;
    bottom: 60px;
    border: none;
    outline: none;
    background: linear-gradient(227deg, rgba(207, 243, 251, 0.25) 0%, rgba(207, 243, 251, 0.00) 0.01%, rgba(207, 243, 251, 0.50) 100%);
    backdrop-filter: blur(6px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2000;
    border: 2px solid #e7f8fc80;
    &:hover {
        .popup_button_icon {
            transform: translateY(-2px);
        }
    }
}

.popup_button_icon {
    transition: all 0.3s ease-in-out;
    path {
        transition: all 0.3s ease-in-out;
        fill: #00ABD0;
    }
    &.icon_white path {
        fill: var(--color-white);
    }
}

.popup_button_outline {
    position: absolute;
    top: -2px;
    left: -2px;
}