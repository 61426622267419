.bubble_generator {
  width: calc(100% + 100px);
  height: calc(100% + 100px);
  position: fixed;
  top: -100px;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
}

.canvas {
  width: 100%;
  height: 100%;
  z-index: 1;
}
