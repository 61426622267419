.header {
  height: 100vh;
  position: relative;
  padding: 0 50px 0px 240px;
  display: flex;
}

.header_background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}

.arrow_down {
  position: absolute;
  left: 240px;
  bottom: 150px;
  z-index: 3;
}

.content {
  position: relative;
  z-index: 2;
  display: flex;
  gap: 20px;
  align-items: center;
  padding-bottom: 100px;
}

.left_side_content {
  width: calc(40% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}

.right_side_content {
  width: calc(60% - 20px);
  height: fit-content;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.mockup_image {
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.title {
  color: var(--color-white);
  font-size: 40px;
  font-weight: 600;
}
.subtitle {
  color: var(--color-white);
  font-size: 20px;
  font-weight: 500;
}
