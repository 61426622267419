$animation-time: 0.8s;
$hide-time: 0.5s;
$color-delay: 0.3s;
$change-color: 0.5s;

.btn {
  position: relative;
  z-index: 15;
  width: fit-content;
  height: fit-content;
  padding: 15px 25px;
  overflow: hidden;
  border-radius: 25px;
  .btn_text {
    color: #014d90;
    transition: all calc($animation-time - $color-delay) ease-out;
    transition-delay: $color-delay;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .border {
    border-radius: 25px;
    border: 2px solid #fff;
    opacity: 0;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    position: absolute;
    transition: all $change-color ease-out;
    transition-delay: $animation-time;
  }
  circle {
    transition: all $hide-time ease-out;
    transition-delay: $animation-time;
  }
  &:hover {
    .btn_bg {
      transform: translateY(0%);
    }
    .btn_text {
      color: #fff;
    }
    .border {
      opacity: 1;
    }
    circle {
      opacity: 0;
    }
  }
}
.btn:before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 25px;
  background: linear-gradient(
    to right,
    rgba(1, 77, 144, 70%),
    rgba(2, 155, 187, 70%)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.btn_bg {
  width: 100%;
  height: 89px;
  display: block;
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  object-fit: contain;
  transition: all $animation-time ease-out;
  z-index: -1;
  svg {
    width: 100%;
    height: 100%;
  }
}