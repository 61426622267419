.container {
  padding: 0px 240px;
  position: relative;
  z-index: 2;
}

.title_main {
  color: var(--gray-gray-6);
  font-size: 36px;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}

.anchor {
  position: absolute;
  width: 0px;
  height: 0px;
  background-color: red;
  left: 50%;
  top: -60px;
}

.title {
  text-align: center;
  color: var(--gray-gray-6);
  font-size: 20px;
  font-weight: 500;
  margin-top: 40px;
}

.feat_wrap {
  margin: 100px 0px;
  height: 614px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 150px;
  row-gap: 80px;
  padding: 0 90px;
}

.item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
  &_title {
    margin: 27px 0 15px 0px;
    color: var(--gray-gray-6);
    font-size: 24px;
    font-weight: 600;
  }
  &_descr {
    color: var(--gray-gray-6);
    font-size: 16px;
    font-weight: 500;
  }
}
