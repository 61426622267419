.header {
  background: url('../../Assets/images/small_header.png');
  height: 50vh;
  background-repeat: no-repeat;
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  background-position: bottom;
  .title {
    color: var(--color-white);
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 25px;
  }
  .delimiter {
    color: var(--color-white);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .btns_row {
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 2;
  }
  .toggle_btn {
    color: rgba(255, 255, 255, 70%);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    border: none;
    padding: 5px;
    outline: none;
    box-shadow: none;
    transition: all 0.5s ease-out;
    &__active {
      color: var(--color-white);
    }
    &:hover {
      background-color: transparent;
      color: var(--color-white);
    }
    &::before {
      background-color: transparent;
    }
  }
}
