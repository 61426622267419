.wrapper {
  position: relative;
  display: none;
  opacity: 0;
  z-index: 1000;
  &_active {
    display: block;
    animation: visible 1s ease-out forwards;
  }
}

@keyframes visible {
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
