.container {
  padding: 0 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .title {
    color: var(--color-gray-6);
    font-family: Raleway;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }
  .tech_block {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1260px;
    gap: 50px;
    margin-bottom: 400px;
    .descr {
      color: var(--color-gray-6);
      text-align: center;
      font-variant-numeric: lining-nums proportional-nums;
      font-family: Raleway;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .connection_row {
      display: flex;
      justify-content: center;
      gap: 80px;
    }
    .connect_item {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    .connect_link {
      color: var(--color-gray-6);
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .btn {
      border: 1px solid black;
      color: black;
    }
  }
  .scheme_block {
    position: absolute;
    width: 100%;
    height: fit-content;
    padding: 0 295px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    bottom: -460px;
    z-index: 10;

    .glass_card {
      border-radius: 25px;
      border: 2px solid #fff;
      background-color: transparent;
      box-shadow: 0px -1px 5px 0px rgba(1, 115, 161, 0.15);

      padding: 40px;
      position: relative;
      overflow: hidden;
      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 25px;
        border: 2px solid #fff;
        background: linear-gradient(
          150deg,
          rgba(255, 255, 255, 0.9) -8.11%,
          rgba(255, 255, 255, 0) 111.66%
        );
        box-shadow: 0px -1px 5px 0px rgba(1, 115, 161, 0.15);
        backdrop-filter: blur(4px);
        z-index: -1;
        filter: blur(4px);
        backdrop-filter: blur(4px);
      }
    }
  }
  .table {
    background-color: var(--color-white);
    width: 100%;
    height: 100%;
    border-radius: 25px;
    padding: 40px;
    z-index: 2;
  }
  .grid_row {
    display: grid;
    grid-template-columns: 12% 44% 13.7% auto;
    width: 100%;
    grid-template-rows: auto;
    border-left: 1px solid var(--color-blue-10);
    border-bottom: 1px solid var(--color-blue-10);
    &:first-of-type {
      border: none;
      .cell {
        background-color: var(--color-blue-10);
        border-right: 1px solid var(--color-white);
        &:last-of-type {
          border: none;
        }
      }
    }
    .cell {
      padding: 20px;
      color: var(--color-gray-6);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-right: 1px solid var(--color-blue-10);
    }
  }
}
