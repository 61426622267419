.arrow_down {
    display: flex;
    flex-direction: column;
    height: auto;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    margin: 0;
    &:hover {
        .arrow_down_icon {
            transform: translateY(20px);
        }
    }
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
}
.arrow_down_icon {
    overflow: visible;
    transition: all 0.5s ease-in-out;
}

