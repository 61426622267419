.container {
  background: url('../../Assets/images/advantages_bg.png');
  background-size: auto;
  padding: 250px 240px 0px;
  display: flex;
  flex-direction: column;
  gap: 230px;
  z-index: 2;
}

.row {
  display: flex;
  gap: 110px;
}

.flex_wrap {
  display: flex;
  justify-content: space-between;
  max-height: 50px;
}
.paralax_right {
  transform: translateX(200px);
  transition: all 1s linear;
}

.descr {
  width: 610px;
  display: flex;
  flex-direction: column;
  gap: 43px;
  position: relative;
  &_title {
    color: var(--color-gray-6);
    font-size: 32px;
    font-weight: 600;
    font-variant-numeric: lining-nums proportional-nums;
  }
  &_text {
    color: var(--color-gray-6);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.transition {
  transition: all 1s linear;
}

.descr, .transition, .paralax_right {
  will-change: transform;
}
