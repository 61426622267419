.wrapper {
  background-color: var(--color-white);
  background-image: url('../../Assets/images/cookies_bg.jpg');
  background-size: cover;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}

.descr {
  color: #262626;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.link {
    color: var(--color-blue-3);
}