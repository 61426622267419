.grid_row {
    display: grid;
    grid-template-columns: 12% 44% 13.7% auto;
    width: 100%;
    grid-template-rows: auto;
    border-left: 1px solid var(--color-blue-10);
    border-bottom: 1px solid var(--color-blue-10);
    &:first-of-type {
      border: none;
      .cell {
        background-color: var(--color-blue-10);
        border-right: 1px solid var(--color-white);
        &:last-of-type {
          border: none;
        }
      }
    }
    .cell {
      padding: 20px;
      color: var(--color-gray-6);
      font-variant-numeric: lining-nums proportional-nums;
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border-right: 1px solid var(--color-blue-10);
    }
    &_double {
      grid-template-columns: 1fr 1fr;
    }
  }