.top_navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  padding: 10px 240px;
  height: 70px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  transition: all 0.3s ease-in-out;
  z-index: 10000;
  .btns_wrap {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &:not(&.transparent) {
    .logo {
      path {
        fill: var(--color-white);
      }
    }
  }
  &.transparent {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);
    height: 70px;

    .menu_item {
      color: var(--color-black);
    }
  }
}

.logo {
  width: 130px;
  height: 40px;
  transition: all 0.3s ease-in-out;
}

.nav_links {
  display: flex;
  align-items: center;
  gap: 60px;
  justify-content: space-around;
}

.menu_item {
  text-decoration: none;
  color: var(--color-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  position: relative;
  outline: none;

  &::after {
    content: '';
    position: absolute;
    transform: scaleX(0);
    height: 2px;
    bottom: -2px;
    left: -5px;
    right: -5px;
    background-color: #51bad0;
    transform-origin: bottom right;
    border-radius: 1px;
    transition: transform 0.25s ease-out;
  }
  &:hover {
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
  &.menu_item_active {
    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}
