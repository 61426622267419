.button {
  display: flex;
  padding: 15px 25px;
  justify-content: center;
  align-items: center;

  border-radius: 25px;
  background: transparent;
  border: 2px solid var(--color-white);
  color: var(--color-blue-1);
  font-size: 16px;
  font-weight: 600;
  outline: none;
  line-height: normal;
  width: fit-content;
  position: relative;

  cursor: pointer;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    z-index: -1;
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    &::before {
      width: 200px;
      height: 200px;
    }
  }
}
