.card {
  display: flex;
  justify-content: space-between;
  gap: 60px;
  border-radius: 25px;
  box-shadow: 0px 0px 5px 0px rgba(1, 115, 161, 0.15);
  &_descr {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 50px;
    max-width: 635px;
  }
  &_title {
    color: var(--color-blue-3);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }
  &_text {
    color: var(--color-gray-6);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  &_video {
    width: 635px;
    height: 380px;
    background-image: url('../../Assets/images/video_bg.png');
    margin: 10px;
  }
}
