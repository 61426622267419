.container {
  padding: 0 240px;
  .main_title {
    color: var(--color-gray-6);
    font-size: 40px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 50px;
  }
  .sub_title {
    color: var(--color-gray-6);
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}
