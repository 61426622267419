.container {
  padding: 204px 240px 0px;
  height: 450px;
  z-index: 2;
}

.grid_wrap {
  display: flex;
  justify-content: space-between;
  .info_wrap {
    width: 563px;
    display: flex;
    flex-direction: column;
    .title_main {
      color: var(--color-gray-6);
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 40px;
    }
    .text {
      color: var(--color-gray-6);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      &_small {
        font-size: 14px;
        margin-top: 100px;
        margin-bottom: 38px;
        font-weight: 500;
      }
    }
    .numeric {
      font-variant-numeric: lining-nums proportional-nums;
    }
    .contacts_wrap {
      display: flex;
      gap: 63px;
      .contact_item {
        display: flex;
        gap: 27px;
        align-items: center;
      }
    }
  }
  .form {
    width: 520px;
    border-radius: 25px;
    border: 2px solid var(--color-white);
    background: linear-gradient(
      150deg,
      rgba(255, 255, 255, 0.9) -8.11%,
      rgba(255, 255, 255, 0) 111.66%
    );
    padding: 40px;
    box-shadow: 0px -1px 5px 0px rgba(1, 115, 161, 0.15);
    backdrop-filter: blur(6px);
    display: flex;
    flex-direction: column;
    z-index: 1;
    &_title {
      color: var(--gray-6);
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 40px;
    }
    &_text {
      color: var(--color-gray-6);
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      width: fit-content;
    }
    .input {
      border-radius: 20px;
      border: 1.5px solid #6ccee2;
      margin-bottom: 30px;
      padding: 12px 20px;
      offset: none;
      outline: none;
    }
    .textarea {
      border-radius: 20px;
      border: 1.5px solid #6ccee2;
      margin-bottom: 30px;
      padding: 12px 20px;
      offset: none;
      outline: none;
      resize: none;
    }
    .checkbox_row {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 90px;
    }
    .checkbox {
      cursor: pointer;
      width: 20px;
      height: 20px;
      border: 1px solid var(--color-blue-4);
      border-radius: 4px;
    }
    &_btn {
      margin-left: auto;
    }
  }
}


