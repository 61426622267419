.root {
  height: 100%;
  .wrapper {
    width: 100%;
    height: 10%;
    padding: 25px 240px;
    display: flex;
    background: transparent;
    position: fixed;
  }
  .page {
    height: 100%;
  }
}

.hidden {
  overflow: hidden;
}

@media (max-width: 1366px) {
  .root {
    .page {
      padding: 10px;
    }
  }
}
