.paragraph {
  margin-bottom: 50px;
}

.main_title {
  color: var(--color-gray-6);
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 50px;
}
.sub_title {
  color: var(--color-gray-6);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 50px;
  margin-top: 50px;
}
.title {
  color: var(--color-gray-6);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 20px;
  font-weight: 700;
  line-height: 180%;
  margin-bottom: 30px;
}
.flex {
  display: flex;
  margin-bottom: 15px;
  color: var(--color-gray-6);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 20px;
  font-weight: 500;
  line-height: 180%;
  gap: 5px;
}
.contents {
  display: contents;
}
.dot {
  display: block;
  margin: 17px 5px;
  background-color: var(--color-gray-6);
  min-width: 5px;
  height: 5px;
  border-radius: 50%;
}
.flex_dot {
  display: flex;
  color: var(--color-gray-6);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 20px;
  font-weight: 500;
  line-height: 180%;
  gap: 5px;
}
.link {
  color: var(--color-blue-3);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 700;
  line-height: 180%;
}
.mb_15 {
  color: var(--color-gray-6);
  text-align: justify;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  margin-bottom: 15px !important;
}

.bold {
  font-weight: 700;
}
