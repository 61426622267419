.footer {
  height: 100vh;
  position: relative;
  display: flex;
  z-index: 0;
  flex-direction: column;
  justify-content: flex-end;
}
.footer_background {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
  z-index: 0;
}

.container {
  padding: 0 240px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  width: 100%;
  height: fit-content;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
}

.col_logo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 200px;
  .logo {
    width: 180px;
    height: 55px;
  }
  &__text {
    color: var(--color-white);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
}

.col_links {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .link {
    color: var(--color-white);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
  }
}

.col_partners {
  display: flex;
  flex-direction: column;
  text-align: center;
  &__logos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  &__title {
    color: var(--color-white);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
  }
}

.delimiter {
  width: 100%;
  height: 1px;
  background-color: #819cc3;
  display: block;
  z-index: 1;
}

.footer_reserved {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0 240px;
  z-index: 2;
}

.subtitle {
  z-index: 1;
  color: var(--color-white);
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 5px;
  .text_blue {
    color: var(--color-blue-9);
  }
}
