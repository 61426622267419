.glass {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 30px;
  border-radius: 25px;
  border: 2px solid #fff;
  background: linear-gradient(
    150deg,
    rgba(255, 255, 255, 0.9) -8.11%,
    rgba(255, 255, 255, 0) 111.66%
  );
  box-shadow: 0px -1px 5px 0px rgba(1, 115, 161, 0.15);
  backdrop-filter: blur(4px);
}
.close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: fit-content;
  height: fit-content;
}
.form {
  width: 590px;
  height: 636px;
  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  overflow: hidden;
  &_title {
    color: var(--color-gray-6);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  &_subtitle {
    color: var(--color-gray-6);
    font-weight: 600;
    margin-bottom: 40px;
  }
  &_text {
    color: var(--color-gray-6);
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    width: fit-content;
  }
  .input {
    border-radius: 20px;
    border: 1.5px solid #6ccee2;
    margin-bottom: 20px;
    padding: 12px 20px;
    offset: none;
    outline: none;
  }
  .textarea {
    border-radius: 20px;
    border: 1.5px solid #6ccee2;
    margin-bottom: 30px;
    padding: 12px 20px;
    offset: none;
    outline: none;
    resize: none;
  }
  .checkbox_row {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 50px;
  }
  .checkbox {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-blue-4);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
    &_active {
      background-color: var(--color-blue-4);
    }
  }
  &_btn {
    margin-left: auto;
    z-index: 5;
  }
  .second_layout {
    background: url('../../Assets/images/form_bg.png');
    height: 1280px;
    background-position: center;
    position: absolute;
    bottom: -200%;
    left: 0;
    width: 100%;
    transition: all 2s ease-in-out;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0.5;
    &__active {
      opacity: 1;
      bottom: -5px;
    }
    .content {
      height: 50%;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 30px;
      align-items: center;
      justify-content: space-around;
      .big_title {
        color: var(--color-gray-6);
        font-size: 40px;
        font-weight: 600;
        text-align: center;
      }
      .big_descr {
        color: var(--color-gray-6);
        text-align: center;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
