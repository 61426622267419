.border {
  position: relative;
  z-index: 0;
  width: 700px;
  height: 450px;
}
.border:before {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 25px;
  background: linear-gradient(
    to bottom right,
    var(--color-blue-12),
    var(--color-blue-7)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  border: 2px solid #fff;
  background: linear-gradient(
    150deg,
    rgba(255, 255, 255, 0.9) -8.11%,
    rgba(255, 255, 255, 0) 111.66%
  );
  box-shadow: 0px -1px 5px 0px rgba(1, 115, 161, 0.15);
  backdrop-filter: blur(4px);

  padding: 20px;
  box-sizing: border-box;
  &__top_left {
    transform: translateX(-20px) translateY(-20px);
  }
  &__top_right {
    transform: translateX(20px) translateY(-20px);
  }
  &__bottom_left {
    transform: translateX(-20px) translateY(20px);
  }
  &__bottom_right {
    transform: translateX(20px) translateY(20px);
  }
  &_img {
    background: url('../../Assets/images/card_screen_1.png');
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
}
