.container {
  display: flex;
  flex-direction: column;
  padding: 0 280px;
  gap: 50px;
  .title {
    color: var(--color-gray-6);
    font-family: Raleway;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .list {
    color: var(--color-gray-6);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    margin-bottom: 30px;
  }
  .bold {
    font-weight: 700;
  }
}
