* {
  font-family: 'Raleway', sans-serif;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
}

:root {
  --color-white: #fff;
  --color-black: #000;

  --color-blue-1: #e5f3ff;
  --color-blue-2: #b3cde0;
  --color-blue-3: #0078bb;
  --color-blue-4: #015697;
  --color-blue-5: #58bdd2;
  --color-blue-6: #001cff;
  --color-blue-7: #66efef;
  --color-blue-7: #00b7de;
  --color-blue-8: #6ccee2;
  --color-blue-9: #50b3ff;
  --color-blue-10: #abdbe5;
  --color-blue-11: #bbedf8;
  --color-blue-12: #0069FF;

  --color-gray-1: #fafbfd;
  --color-gray-2: #dce6f1;
  --color-gray-3: #bfc3cb;
  --color-gray-4: #7a848e;
  --color-gray-5: #475459;
  --color-gray-6: #262626;
  --color-gray-7: #f8fdff;
  --color-gray-8: #bbbbbb;
  --color-gray-bg: #f6f8fb;

  --color-priority-1: #efefef;
  --color-priority-2: #bae299;
  --color-priority-3: #fbc87c;
  --color-priority-4: #f78686;

  --color-validation-1: #ff9e9e;

  --color-accent-1: #2689b3;
}

body {
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
}

.h2 {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
}

.h3 {
  margin: 0;
  font-size: 15px;
  line-height: 18px;
}

.text {
  &_common {
    font-size: 14px;
    line-height: 17px;
  }
  &_small {
    font-size: 12px;
    line-height: 15px;
  }
}
