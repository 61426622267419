.modal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  transition: all 0.4s ease-in-out;
  display: none;
  opacity: 0;
  animation: hide 0.4s;
}

.active {
  animation: show 0.4s;
  display: block;
  opacity: 1;
}

.hide {
  animation: hide 0.4s;
  display: none;
  opacity: 0;
}

@keyframes show {
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    display: none;
  }
}
